<template>
  <div class="container-fluid">
    <div class="row min-vh-100 flex-center no-gutters">
      <div class="col-lg-8 col-xxl-5 py-3">
        <img 
          class="bg-auth-circle-shape" 
          src="@/assets/img/illustrations/bg-shape.png" 
          alt="" width="250">
        <img 
          class="bg-auth-circle-shape-2" 
          src="@/assets/img/illustrations/shape-1.png" 
          alt="" width="150">
        <div class="card overflow-hidden z-index-1 theme-wizard">
          <div class="card-body p-0">
            <div class="row no-gutters h-100">
              <div class="col-md-5 text-white text-center bg-card-gradient">
                <div class="position-relative p-4 pt-md-4 pb-md-9">
                  <div 
                    class="bg-holder bg-auth-card-shape" 
                    style="background-image:url(/img/illustrations/half-circle.png);">
                  </div>
                  <!--/.bg-holder-->

                  <div class="z-index-1 position-relative">
                     <router-link class="navbar-brand fs-5 mt-2" to="/" exact>
                      <div class="d-flex align-items-center text-primary ">
                        <img 
                        class="mr-1" 
                        src="@/assets/img/illustrations/bplan-logo.png" style="filter: contrast(150%);" alt="" width="45" />
                        <span class="text-white font-proxima">ТВОЙ<small>план</small> </span>
                      </div>
                    </router-link>
                      
                  </div>
                </div>
                <p class="text-900 font-proxima p-4">
                  Создавать профессиональные <br> 
                  бизнес-планы - <strong class="fs-2">легко!</strong>
                </p>
                <div class="mt-0 mb-4 mt-md-6 mb-md-5">
                  <p v-if="!userInfo"
                    class="pt-0 pt-md-3 mt-0 text-900">
                    Уже зарегистрированы?
                    <br>
                    <router-link 
                      class="btn btn-outline-light btn-sm mt-3 px-4 text-800" 
                      to="/login">Войти
                    </router-link>
                  </p>
                  <p v-if="userInfo"
                    class="pt-0 pt-md-3 mt-0 text-900">
                    Вы вошли как {{userInfo.firstName}}, <br> 
                    <span v-if="regStage == 2">необходимо завершить регистрацию! <br></span>
                    <span v-if="regStage == 3">регистрация завершена, поздравляем! <br></span>
                    <button
                      class="btn btn-outline-light btn-sm mt-3 px-4 text-800" 
                      @click="logout">
                      Выйти
                    </button>
                  </p>
                </div>
              </div>
              <div class="col-md-7">
                  <div class="col-12 py-1 px-0 border-bottom">
                    <ul class="nav justify-content-between nav-wizard">
                      <li class="nav-item text-center">
                        <div 
                        class="nav-link font-weight-semi-bold" 
                        :class="{'active': regStage == 1, 'done': regStage > 1}">
                        <span class="nav-item-circle-parent">
                          <span class="nav-item-circle">
                            <svg 
                              class="svg-inline--fa fa-lock fa-w-14" 
                              aria-hidden="true" 
                              focusable="false" 
                              data-prefix="fas" 
                              data-icon="lock" 
                              role="img" 
                              xmlns="http://www.w3.org/2000/svg" 
                              viewBox="0 0 448 512" 
                              data-fa-i2svg="">
                              <path 
                                fill="currentColor" 
                                d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z">
                              </path>
                            </svg>
                            <!-- <span class="fas fa-lock"></span> -->
                          </span>
                        </span>
                        <span 
                          class="d-none d-md-block mt-1 fs--1 font-proxima">
                          Аккаунт
                        </span>
                      </div>
                      </li>
                      <li class="nav-item text-center">
                        <div 
                          class="nav-link font-weight-semi-bold" 
                          :class="{'active': regStage == 2, 'done': regStage > 2}">
                          <span class="nav-item-circle-parent">
                            <span class="nav-item-circle">
                              <svg 
                                class="svg-inline--fa fa-dollar-sign fa-w-9" 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="fas" 
                                data-icon="dollar-sign" 
                                role="img" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 288 512" 
                                data-fa-i2svg="">
                                <path 
                                  fill="currentColor" 
                                  d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z">
                                </path>
                              </svg>
                              <!-- <span class="fas fa-dollar-sign"></span> --></span>
                            </span>
                            <span class="d-none d-md-block mt-1 fs--1 font-proxima">
                              Оплата
                            </span>
                          </div>
                        </li>
                      <li class="nav-item text-center">
                        <div 
                          class="nav-link font-weight-semi-bold"
                          :class="{'active': regStage == 3, 'done': regStage > 2}">
                          <span class="nav-item-circle-parent">
                            <span class="nav-item-circle">
                              <svg 
                                class="svg-inline--fa fa-thumbs-up fa-w-16" 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="fas" 
                                data-icon="thumbs-up" 
                                role="img" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 512 512" 
                                data-fa-i2svg="">
                                <path 
                                  fill="currentColor" 
                                  d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z">
                                </path>
                              </svg>
                              <!-- <span class="fas fa-thumbs-up"></span> -->
                            </span>
                          </span>
                          <span class="d-none d-md-block mt-1 fs--1 font-proxima">
                            Вперед!
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                                  
                <div class="pt-4 pb-4 pb-md-5 px-4 px-md-5 flex-grow-1 font-proxima">
                  <form v-if="regStage == 1">
                    <h3 class="font-proxima">Регистрация</h3>
                    <div class="form-group">
                      <label id="name-label" for="name">Имя</label>
                      <input 
                        id="name"
                        class="form-control font-proxima" 
                        type="text" 
                        placeholder="Введите имя"
                        v-model.trim="$v.name.$model"
                        :disabled="loading"
                        :class="{
                          'is-invalid': $v.name.$error,
                          'is-valid': ($v.name.$dirty && !$v.name.error)
                        }"  
                      />
                    </div>
                    <div class="form-group">
                      <label for="card-email">Email</label>
                      <input 
                        class="form-control font-proxima" 
                        type="email" 
                        placeholder="Адрес электронной почты"
                        v-model.trim="$v.email.$model"
                        :disabled="loading"
                        :class="{
                          'is-invalid': $v.email.$error,
                          'is-valid': ($v.email.$dirty && !$v.email.$error)
                        }"
                      />
                    </div>
                    <div class="form-row">
                      <div class="form-group col-12 col-md-6">
                        <label for="card-password">Пароль</label>
                        <input 
                          class="form-control font-proxima" 
                          type="password" 
                          v-model.trim="$v.password.$model" 
                          @change="$v.password2.$model = ''"
                          :disabled="loading"
                          :class="{
                          'is-invalid': $v.password.$error,
                          'is-valid': ($v.password.$dirty && !$v.password.$error)
                          }" 
                        />
                      </div>
                      <div class="form-group col-12 col-md-6">
                        <label for="card-confirm-password">
                          Подтвердите пароль
                        </label>
                        <input 
                          class="form-control font-proxima" 
                          type="password" 
                          v-model.trim="$v.password2.$model"
                          :disabled="loading" 
                          :class="{
                            'is-invalid': $v.password2.$error,
                            'is-valid': $v.password2.$dirty && !$v.password2.$error && $v.password2.$model != ''
                          }"
                        />
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input 
                        id="customCheckTerms"
                        class="custom-control-input" 
                        type="checkbox"
                        v-model="agreeRules"
                        :disabled="loading"
                        :class="{
                          'is-invalid': $v.agreeRules.$error,
                        }"  
                      />
                      <label 
                        class="custom-control-label" 
                        for="customCheckTerms">
                        Я принимаю
                        <a href="#!">условия</a> и
                        <a href="#!">политику конфиденциальности</a>
                      </label>
                    </div>
                    <div class="form-group">
                      <button 
                        class="btn btn-primary btn-block mt-4" 
                        type="submit" 
                        name="submit"
                        @click.prevent="submitRegistration">
                        <span 
                          v-if="loading"
                          class="spinner-border spinner-border-sm" 
                          role="status" 
                          aria-hidden="true">
                        </span>
                        Создать аккаунт
                      </button>
                    </div>
                  </form>

                  <!-- Форма платежа -->
                  <div v-if="regStage == 2" id="payment-form">
                    <h3 class="font-proxima mb-3">Данные подписки</h3>
                    <h6 class="font-proxima text-right">
                      <router-link to="/pricing">
                        Ознакомиться с тарифными планами
                      </router-link>
                    </h6>
                    <custom-dropdown
                      class="mb-3"
                      v-model="paymentSelected"
                      align="left"
                      :defaultItem="0"
                      :selected="paymentType"
                      placeholder="Выберие подписку" >
                    </custom-dropdown>
                    <b-form-group v-if="promo"
                      :state="promoState">
                      <b-form-input 
                        id="promoName"
                        class="mb-2"
                        v-model="promoName"
                        :state="promoState">
                      </b-form-input>
                    </b-form-group>
                    <div v-if="!promo">
                      <div class="d-flex justify-content-between fs--0 mb-0">
                        <p class="mb-0">
                          Обычная стоимость за год:
                        </p>
                        <span class="text-sans-serif">11 400 ₽</span>
                        </div>
                      <div class="d-flex justify-content-between fs--1 mb-1"
                        :class="{
                          'text-success': discount > 0, 
                          'text-300': discount == 0,
                        }">
                        <p class="mb-0">Экономия за год от выбранного плана</p>
                        <span class="text-sans-serif">
                          {{discount | numeralFormat('0,0') }} ₽  
                        </span>
                      </div>
                    </div>
                    <hr>
                    <h5 class="d-flex justify-content-between">
                      <span>К оплате {{paymentDesc}}</span>
                      <span>{{paymentAmount | numeralFormat('0,0')}} ₽</span>
                    </h5>
                    <p class="fs--2 text-600 mt-2 font-proxima">
                      Если по какой-либо причине вам не понравится наш сервис, 
                      просто сообщите нам об этом в течение 30 дней (по электронной почте, 
                      телефону или чате приложения) и 
                      <strong class="text-600">мы вернем вам деньги</strong>.
                    </p>
                    
                    <button 
                      class="btn btn-primary btn-block"
                      type="submit"
                      @click.prevent = 'createPayment'>
                      <span class="fa fa-lock mr-2"></span>
                      Перейти к оплате
                    </button>
                    <div class="text-center mt-3">
                      <small class="d-inline-block font-proxima">
                        Продолжив, вы подтверждаете, что ознакомились с  
                        <a v-b-modal.userAgreementModal href="#">
                          пользовательским соглашением
                        </a> 
                        и согласны с его положениями.
                      </small>
                      <user-agreement-modal />
                    </div>
                  </div>
                  <!-- Уведомление о завершении регистрации -->
                  <div 
                    v-if="regStage == 3"
                    class="tab-pane text-center px-sm-5 px-md-5 mt-3 active" >
                    <img 
                      class="mb-3" 
                      src="@/assets/img/illustrations/2.png" 
                      alt="" width="250">
                    <h4 class="mb-1">Ваш аккаунт готов!</h4>
                    <p>Теперь вы можете приступить к работе</p>
                    <router-link class="btn btn-primary px-5 my-5" to="/dashboard">
                      Начать
                    </router-link>
                  </div>
                  <div v-if="regStage == 1">
                    <div class="w-100 position-relative mt-5">
                      <hr class="text-300" />
                      <div 
                        class="position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap">
                        или войти при помощи
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <div class="row no-gutters">
                        <div class="col-sm-6 pr-sm-1">
                          <a 
                            class="btn btn-outline-google-plus btn-sm btn-block mt-2 disabled" 
                            href="#">
                            <i 
                              class="fab fa-google-plus-g fa-lg mr-2" 
                              data-fa-transform="grow-8">
                            </i> 
                            google
                          </a>
                        </div>
                        <div class="col-sm-6 pl-sm-1">
                          <a class="btn btn-outline-facebook btn-sm btn-block mt-2 disabled" href="#">
                            <span class="fab fa-facebook fa-lg mr-2" data-fa-transform="grow-8"></span>
                            facebook
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { email, sameAs, required, minLength } from 'vuelidate/lib/validators'

import UserAgreementModal from '../../components/app/modals/UserAgreementModal'
import CustomDropdown from '../../components/app/widgets/CustomDropdown'

export default {
  name: 'registration',
  metaInfo() {
    return {
      title: this.$title('Регистрация'),
      script: [
        { src: 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js', async: true, defer: true }
      ]
    }
  },
  data: () => ({
    name: null,
    email: '',
    password: '',
    password2: '',
    agreeRules: false,
    loading: false,
    // regStage: 1,
    promo: false,
    promoName: '',
    paymentType: null,
    discount: 5700,
    paymentAmount: 5700,
    paymentDesc: '(раз в год)',
    paymentId: 0
  }),

  components: {
    UserAgreementModal,
    CustomDropdown
  },

  validations: {
    name: {required},
    email: {email, required},
    password: {required, minLength: minLength(6)},
    password2: {sameAsPassword: sameAs('password')},
    agreeRules: {checked: v => v}
  },

  async mounted() {
    // let tinkoffPay = document.createElement('script');
    let googlePay = document.createElement('script');

    this.$store.dispatch('getUserInfo');

    // tinkoffPay.setAttribute(
    //   'src', 
    //   'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js');
    // document.head.appendChild(tinkoffPay)
    googlePay.setAttribute(
      'src', 
      'https://pay.google.com/gp/p/js/pay.js')
    // let googlePayLoaded = await document.head.appendChild(googlePay)
    // this.onGooglePayLoaded()
  },

  computed: {
    
    userInfo() {
      const userInfo = this.$store.getters.userInfo
      return userInfo
    },

    paymentSelected: {
      get: function() {
        return [
          { id: 0, title: 'Подписка на год',
            subscriptionMonths: 12, 
            discount:  5700, 
            monthly: 475, 
            paymentAmount: 5700, 
            paymentDesc: '(раз в год)' 
          }, { 
            id: 1, title: 'Подписка на 3 месяца', 
            subscriptionMonths: 3, 
            discount:   2880, 
            monthly: 710, 
            paymentAmount: 2130, 
            paymentDesc: '(раз в квартал)'
          }, { 
            id: 2, title: 'Подписка на 1 месяц', 
            subscriptionMonths: 1, 
            discount:  0, 
            monthly: 950, 
            paymentAmount: 950, 
            paymentDesc: '(раз в месяц)' 
          }, { 
            id: 3, title: 'Промокод', 
            subscriptionMonths: 1, 
            annually:  null, 
            monthly: null, 
            paymentAmount: 950, 
            paymentDesc: '(разово)' 
          },
        ]
      },
      set: function(value) {
        if (value) {
          this.paymentType = value;
          this.paymentAmount = value.paymentAmount;
          this.discount = value.discount;
          this.paymentDesc = value.paymentDesc;
          this.paymentId = value.id;
          value.id == 3 ? this.promo = true : this.promo = false;
          if (value.id != 3) {
            this.promoName = '';
          }
        } 
      }
    }, 

    regStage: {
      get: function() {
        if (this.userInfo && (this.regCompleted)) {
          return 3;
        } else if (this.userInfo) {
          return 2;
        } else {
          return 1;
        }
      },
      set: async function() {
        await this.$store.dispatch('getUserInfo');
      }
    },

    regCompleted() {
      if (this.userInfo) {
        return this.userInfo.regCompleted
      } else {
        return false
      }
    },

    promoState() {
      if (this.promoName == 'friends') {
        this.setPromoPayment();
        return  true
      } else {
        return false
      }
    },

    invalidPromoFeedback() {
      if (!this.promoName) {
        return 'Пожалуйства, введине название...'
      } else if (this.promoName == 'friends') {
        return ''
      } else {
        return 'Промоакция не найдена'
      }
    },

    receipt() {
      let reciptData = {
        "Email": this.userInfo.email,
        "Phone": this.userInfo.phone || null,
        "EmailCompany": "payments@rsystems.io",
        "Taxation": "usn_income_outcome",
        "Items": [{
            "Name": "Подписка по тарифу - Базовый",
            "Price": this.paymentAmount * 100,
            "Quantity": '1.00',
            "Amount": this.paymentAmount * 100,
            "PaymentMethod": "full_prepayment",
            "PaymentObject": "intellectual_activity",
            "Tax": "none" 
          }]
        }
      return JSON.stringify(reciptData)
    },

    orderId() {
      if (this.userInfo) {
        let payment = this.userInfo.payments || {}
        return `${this.userInfo.id}_${Object.values(payment).length + 1 || 1}`
      } else {
        return null
      }
    },

  },

  methods: {
    
    async submitRegistration() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      
      const regData = {
        name: this.name,
        email: this.email,
        password: this.password
      };

      try {
        this.loading = true
        await this.$store.dispatch('registration', regData);
        this.name = '';
        this.email = '';
        this.password = '';
        this.password2 = '';
        this.agreeRules = false;
        this.loading = false;
        this.regStage = 2;
      } catch (error) {
        this.loading = false;
      }
    },

    setPromoPayment() {
      this.paymentAmount = 10;
    },

    async createPayment() {
      const paymentData = {
        date: Date.now(),
        subscriptionType: this.paymentType.id,
        amount: this.paymentAmount, 
        // Прибавляем срок подписки к тебкущей дате
        tillDate: Date.now() + (2629800000 * this.paymentType.subscriptionMonths),
      }
      
      const orderID = await this.$store.dispatch('addUserPayment', paymentData);

      const paymentRequest = {
        TerminalKey: '1568821851852',
        OrderId: orderID,
        "Amount": this.paymentAmount * 100,
        Description: this.paymentType.title,
        DATA: {
          Phone: this.userInfo.phone || null,
          Email: this.userInfo.email
        },
        Receipt: {
          "Email": this.userInfo.email,
          "Phone": this.userInfo.phone || null,
          "EmailCompany": "payments@rsystems.io",
          "Taxation": "usn_income_outcome",
          "Items": [{
            "Name": this.paymentType.title,
            "Price": this.paymentAmount * 100,
            "Quantity": '1.00',
            "Amount": this.paymentAmount * 100,
            "PaymentMethod": "full_prepayment",
            "PaymentObject": "intellectual_activity",
            "Tax": "none" 
          }]
        }
      }

      axios.post(`https://securepay.tinkoff.ru/v2/Init`,    
          paymentRequest
          // body: paymentRequest       // судя из примеров body это тело запроса (axios преобразует автоматом в json формат)
        )
        .then(response => {
          if (response.data.ErrorCode == '0') {
            console.log(response.data.PaymentURL);
            window.location.href = response.data.PaymentURL;
            // this.$router.push(response.data.PaymentURL);
          }
        })
        .catch(e => {
          this.errors.push(e)
        })
    },

    async logout() {
      await this.$store.dispatch('logout')
    }
  }
}
</script>

<style lang="scss">
.tinkoffPayRow{
  display:block;
  margin:1%;
  max-width: 100vh;
}
.t-wrapper {
  padding: 1rem 0 !important;
}


</style>
